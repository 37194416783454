import React from 'react'
import { Collapse } from 'antd'
const FaqComponent = () => {
  const { Panel } = Collapse
  return (
    <>
      <div className="container mx-auto py-9 md:py-16">
        <div className="w-[85%] md:w-4/5 mx-auto">
          <h1 className="font-playfair text-headingFive md:text-headingOne md:font-semibold text-merlot">
            Frequently Asked Questions
          </h1>
          <div className="bg-white shadow-profileCard rounded-lg mt-5">
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header="About MedReps" key="1">
                <div className="px-5 md:px-8">
                  <div className="mb-2">
                    <p className="font-sans text-lg mb-2 font-semibold">
                      What is MedReps.com?
                    </p>
                    <p className="font-sans text-sm leading-6">
                      MedReps is medical sales job site. We work with top
                      employers, experienced recruiters and medical sales
                      professionals to provide medical sales job opportunities,
                      career advice, and salary information.
                    </p>
                  </div>
                  <div className="mb-0">
                    <p className="font-sans text-lg mb-2 font-semibold">
                      Will MedReps help me find a job?
                    </p>
                    <p className="font-sans text-sm leading-6">
                      MedReps.com is not a staffing agency. We’re an exclusive
                      online job site where members may search and apply to
                      positions with industry employers and staffing agencies.
                      We provide a lot of content designed to assist your
                      search, but we don’t offer one-on-one career counselling
                      or job placement services.
                    </p>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="bg-white shadow-profileCard rounded-lg mt-8">
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header="Is MedReps right for you?" key="1">
                <div className="px-5 md:px-8">
                  <div className="mb-2">
                    <p className="font-sans text-lg mb-2 font-semibold">
                      Does MedReps have internships or entry-level positions?
                    </p>
                    <p className="font-sans text-sm leading-6">
                      There are no internship jobs on MedReps. And while some
                      jobs are classified as entry-level, an entry-level medical
                      sales job generally requires some business to business
                      sales experience.
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="font-sans text-lg mb-2 font-semibold">
                      Do jobs on MedReps require experience?
                    </p>
                    <p className="font-sans text-sm leading-6">
                      With a few exceptions, the jobs on MedReps require a
                      minimum of 2 years business to business sales experience.
                      Candidates with medical sales experience are ideal, but
                      those with a strong track record at large b2b companies
                      are also in demand.
                    </p>
                  </div>
                  <div className="mb-0">
                    <p className="font-sans text-lg mb-2 font-semibold">
                      Do jobs on MedReps require a 4-year degree?
                    </p>
                    <p className="font-sans text-sm leading-6">
                      Most of our jobs require a bachelor’s degree (higher may
                      be preferred), and a business, marketing, or life science
                      focus is typically ideal.
                    </p>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="bg-white shadow-profileCard rounded-lg mt-8">
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header="Membership" key="1">
                <div className="px-5 md:px-8">
                  <div className="mb-2">
                    <p className="font-sans text-lg mb-2 font-semibold">
                      Why do you charge a membership fee to view jobs?
                    </p>
                    <p className="font-sans text-sm leading-6">
                      The nominal membership fee filters the applicant pool. Job
                      seekers without the relevant experience are unlikely to
                      pay to view jobs they are not qualified for, so employers
                      and recruiters can expect more qualified applicants. The
                      consistent quality of MedReps candidates explains why so
                      many big-name companies have been posting jobs with us for
                      more than a decade. A membership fee also enables MedReps
                      to re-invest back into the site and provide higher-quality
                      content to improve our members’ experience.
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="font-sans text-lg mb-2 font-semibold">
                      What are the benefits of a MedReps membership?
                    </p>
                    <p className="font-sans text-sm leading-6">
                      MedReps membership grants access to exclusive medical
                      sales jobs and additional career resources. For 20 years
                      MedReps has been the preferred job site of the medical
                      sales community.
                    </p>
                    <ul className="ml-0 font-sans text-sm faq-icon-list">
                      <li className="flex mb-6">
                        <div className="mr-4 flex-shrink-0">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        Unlimited access to medical sales jobs from top
                        employers – view complete job descriptions and apply
                        online
                      </li>
                      <li className="flex mb-6">
                        <div className="mr-4 flex-shrink-0">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        Get customized email job matches
                      </li>
                      <li className="flex mb-6">
                        <div className="mr-4 flex-shrink-0">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        Detailed, up to date medical sales salary information
                      </li>
                      <li className="flex mb-6">
                        <div className="mr-4 flex-shrink-0">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        A searchable profile visible to medical sales employers
                        and recruiters
                      </li>
                      <li className="flex mb-6">
                        <div className="mr-4 flex-shrink-0">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        Insider career advice from employers and recruiters,
                        available exclusively to MedReps members
                      </li>
                    </ul>
                    <p className="font-sans text-lg mb-2 font-semibold">
                      What types of memberships are available?
                    </p>
                    <p className="font-sans text-sm">
                      You can choose your membership billing cycle.
                    </p>
                    <ul className="ml-0 font-sans text-sm faq-icon-list">
                      <li className="flex mb-6">
                        <div className="mr-4 flex-shrink-0">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        Monthly membership – $19.99 per month
                      </li>
                      <li className="flex mb-6">
                        <div className="mr-4 flex-shrink-0">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        Quarterly membership – $29.99 per quarter (Save 50% -
                        $9.99 per month)
                      </li>
                      <li className="flex mb-6">
                        <div className="mr-4 flex-shrink-0">
                          <img src="/icons/icon-check-filled.svg" />
                        </div>
                        Bi-annual membership – $52.99 bi-annually (Save 55% -
                        $8.83 per month)
                      </li>
                    </ul>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="bg-white shadow-profileCard rounded-lg mt-8">
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header="Login Issues" key="1">
                <div className="px-5 md:px-8">
                  <div className="mb-2">
                    <p className="font-sans text-lg mb-2 font-semibold">
                      Why can’t I login?
                    </p>
                    <p className="font-sans text-sm leading-6">
                      If you are a MedReps member and are unable to login, you
                      may want to try a different browser. (Google Chrome or
                      Firefox are the preferred browsers), or you may need
                      to reset your password. If you still can’t login, contact
                      us and we’ll get to the bottom of it!
                    </p>
                  </div>
                  <div className="mb-0">
                    <p className="font-sans text-lg mb-2 font-semibold">
                      Why didn’t you send me instructions to reset my password?
                    </p>
                    <p className="font-sans text-sm leading-6">
                      We’re not ignoring you, we promise. If you’re sure you
                      have an account registered to the email address provided,
                      please check your spam or junk folder for the email from
                      info@medreps.com. If you can’t find the email,
                      please contact us
                    </p>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className="bg-white shadow-profileCard rounded-lg mt-8">
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header="Privacy" key="1">
                <div className="px-5 md:px-8">
                  <div className="mb-0">
                    <p className="font-sans text-lg mb-2 font-semibold">
                      Can I protect my privacy on MedReps?
                    </p>
                    <p className="font-sans text-sm leading-6">
                      Protecting your privacy is as simple as unchecking a few
                      boxes. When creating a profile on MedReps, you will have
                      the option of making it visible to employers and
                      recruiters. You can also hide your resume completely but
                      still store it in the database so you can easily apply for
                      jobs.
                    </p>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  )
}
export default FaqComponent
